// export const followRespectiveIntent=e=>{if(e&&"WHATS_APP_OPEN"==e.action&&validPhoneNumber(e.whatsAppNumber))try{window.JSBridgePlugin.genericIntentLaunch(`whatsapp://send?phone=+91${e.whatsAppNumber}`)}catch(e){console.log(e)}else if(e&&"CALL_START"==e.action&&validPhoneNumber(e.callNumber))try{window.JSBridgePlugin.genericIntentLaunch(`tel:${e.callNumber}`)}catch(e){console.log(e)}else if(e&&"LINK_OPEN"==e.action&&validURL(e.actionURL))try{window.JSBridgePlugin.openInBrowser(e.actionURL)}catch(e){console.log(e)}else console.log("error occured while deciding intent")};export const validPhoneNumber=e=>e&&"string"==typeof e&&10==e.length;export const validURL=e=>e&&"string"==typeof e;export const validUnitIdentifier=e=>e&&"string"==typeof e;

export const followRespectiveIntent = (campaignAction) => {
  if (
    campaignAction &&
    campaignAction.action == "WHATS_APP_OPEN" &&
    validPhoneNumber(campaignAction.whatsAppNumber)
  ) {
    try {
      window.JSBridgePlugin.genericIntentLaunch(
        `whatsapp://send?phone=+91${campaignAction.whatsAppNumber}`
      );
    } catch (err) {
      console.log(err);
    }
  } else if (
    campaignAction &&
    campaignAction.action == "CALL_START" &&
    validPhoneNumber(campaignAction.callNumber)
  ) {
    try {
      window.JSBridgePlugin.genericIntentLaunch(`tel:${campaignAction.callNumber}`);
    } catch (err) {
      console.log(err);
    }
  } else if (
    campaignAction &&
    campaignAction.action == "LINK_OPEN" &&
    validURL(campaignAction.actionURL)
  ) {
    if (campaignAction && campaignAction.actionApplicationId) {
      console.log('==link if===')
      try {
        window.JSBridgePlugin.addCookiesOn(campaignAction.actionURL);
        window.location.href = campaignAction.actionURL
      } catch (err) {
        console.log(err);
      }

    } else {
      console.log('==link else===', campaignAction.actionURL)
      try {
        window.JSBridgePlugin.openInBrowser(campaignAction.actionURL);
      } catch (err) {
        console.log(err);
      }
    }

  } else {
    console.log("error occured while deciding intent");
  }
};

export const validPhoneNumber = (number) => {
  return number && typeof number == "string" && number.length == 10;
};

export const validURL = (url) => {
  return url && typeof url == "string";
};

export const validUnitIdentifier = (unitIdentifier) => {
  return unitIdentifier && typeof unitIdentifier == "string";
};
