import Ad from "./components/Ad";
window.ads91 = {
  install: (app) => {
    app.component("ads-91", Ad);
  },
}
export default {
  install: (app) => {
    app.component("ads-91", Ad);
  },
}
