<template>
  <div class="video-wrapper">
    <div class="customOption" v-if="customOption" @click="emitCustomClicked">
      {{ customOption }}
    </div>
    <div class="arrow" v-if="customOption && videoEnded">
      <span></span> <span></span> <span></span>
    </div>
    <img
      class="video-close"
      src="https://cdn.money91.com/ads/images/x-mark.png"
      alt=""
      @click="closeVideo()"
    />
    <video
      id="mall91-customVideoPlayer"
      class="video-player"
      :poster="poster"
      autoplay
      :paused="paused"
      preload="auto"
      @click="showHideSeekingBar"
    >
      <source :src="videoLink" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div class="duration-bar" v-if="seekingBarVissible">
      <div v-if="video" :style="`width: ${durationBarWidth}%;`"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoPlayer",
  data: () => ({
    paused: !1,
    videoEnded: !1,
    video: null,
    durationBarWidth: 0,
    videoPlayInterval: null,
    seekingBarVissible: !1,
  }),
  props: {
    videoLink: { type: String, required: !0 },
    poster: { type: String, required: !0 },
    customOption: { type: String, required: !1, default: "" },
  },
  mounted() {
    var e = this;
    (this.video = document.getElementById("mall91-customVideoPlayer")),
      this.video.addEventListener("play", function() {
        e.playSeekingBar(),
          e.showHideSeekingBar(),
          (e.videoEnded = !1),
          e.$emit("videoPlayed");
      }),
      this.video.addEventListener("ended", function() {
        e.stopSeekingBar(), (e.videoEnded = !0), e.$emit("videoEnded");
      });
  },
  methods: {
    closeVideo() {
      this.$emit("closeVideo"), (this.paused = !0), this.stopSeekingBar();
    },
    emitCustomClicked() {
      this.$emit("customOptionCliked");
    },
    playSeekingBar() {
      this.videoPlayInterval = setInterval(() => {
        const { duration: e, currentTime: i } = this.video;
        this.durationBarWidth = Math.ceil((i / e) * 100);
      }, 1e3);
    },
    stopSeekingBar() {
      clearInterval(this.videoPlayInterval);
    },
    showHideSeekingBar() {
      (this.seekingBarVissible = !0),
        setTimeout(() => {
          this.seekingBarVissible = !1;
        }, 3e3);
    },
  },
};
</script>
<style scoped>
.video-wrapper {
  background: #0d0d0d;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
}
.video-wrapper .customOption {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 5;
  color: #e9e9e9;
  border: 2px solid #e9e9e9;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.9em;
  font-weight: 600;
}
.video-wrapper .video-player {
  height: 100%;
  width: 100%;
}
.video-wrapper .video-close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 5;
}
.video-wrapper .duration-bar {
  position: absolute;
  bottom: 20px;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  width: 91%;
  background: #d0d0d047;
  height: 5px;
}
.video-wrapper .duration-bar div {
  height: 5px;
  background: #fff;
}
.arrow {
  position: absolute;
  top: 70px;
  left: 50px;
}
.arrow span {
  display: block;
  width: 20px;
  height: 20px;
  border-top: 3px solid #515ed5;
  border-left: 3px solid #515ed5;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>