<template>
  <div class="adsvdo-product" v-if="Object.keys(data).length">
    <div class="img-box">
      <img
        :src="data.campaignMedia[0].videoThumbnailURL"
        v-if="data.campaignMedia[0].videoThumbnailURL"
        alt=""
      />
      <div v-else class="demo-image"></div>
      <span class="play-icon">
        <img @click="markClickedandPLay" src="https://cdn.money91.com/ads/images/play-icon.svg" alt="" />
      </span>
    </div>
    <div class="title-blk">
      <span class="beak-design"></span>
      <span class="label"> <img src="https://cdn.money91.com/ads/images/logo.svg" alt="" /> </span>
    </div>
    <video-player
      v-if="playVideo"
      :poster="data.campaignMedia[0].videoThumbnailURL"
      :videoLink="data.campaignMedia[0].mediaURL"
      :customOption="data.campaignAction.action ? 'Buy Now' : null"
      @closeVideo="closeVideo"
      @customOptionCliked="followIntent"
    />
  </div>
</template>
<script>
import VideoPlayer from "./VideoPlayer";
export default {
  name: "Video",
  components: { VideoPlayer: VideoPlayer },
  data: () => ({ playVideo: !1 }),
  props: { data: { type: Object, required: !0 } },
  methods: {
    closeVideo() {
      this.playVideo = !1;
    },
    followIntent() {
      this.$emit("followIntent");
    },
    markClickedandPLay() {
      (this.playVideo = !0), this.$emit("markClicked");
    },
  },
};
</script>
<style scoped>
.adsvdo-product {
  width: 100%;
  background: #fff;
  position: relative;
  padding: 10px;
  border-radius: 8px;
}
.adsvdo-product .img-box {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin: 0 12px 0 0;
}
.adsvdo-product .img-box img {
  width: 100%;
  height: 24vh;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}
.adsvdo-product .img-box .demo-image {
  width: 100%;
  height: 24vh;
  border-radius: 8px;
  background-image: linear-gradient(#717171, #b1b1b1);
}
.title-blk {
  width: auto;
  position: absolute;
  right: 10px;
  bottom: 5px;
  background: #fff;
  padding: 5px;
}
.title-blk .beak-design {
  border-bottom: 30px solid #fff;
  border-left: 10px solid transparent;
  bottom: 2px;
  position: absolute;
  width: 15px;
  right: 40px;
}
.adsvdo-product .label img {
  height: 18px;
}
.adsvdo-product .play-icon {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.adsvdo-product .play-icon img {
  width: 32px;
  height: 32px;
}
</style>
