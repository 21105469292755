<template>
  <div>
    <div
      class="overlay-bg"
      v-if="isModalOpen"
    ></div>
    <div
      class="custom-mdl confirm-mdl"
      :class="{'open-confirm':isModalOpen}"
    >
      <header class="head-mdl">
        <h2>Are you sure to Pay?</h2>
      </header>
      <section class="mid-mdl">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
          molestiae quas vel sint</p>
      </section>
      <footer class="ftr-mdl">
        <div class="confirm-bot">
          <span
            class="custom-btn cancel-btn"
            @click="$emit('handleCloseModal', false)"
          >
            No
          </span>

          <span class="custom-btn accept-btn">
            Yes
          </span>
        </div>

      </footer>

    </div>

  </div>

</template>

<script>
export default {
  props: ["isModalOpen"],
  emits: ["handleCloseModal"],
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emits("handleCloseModal", false);
    },
  },
  mounted() {},
};
</script>

<style scoped>
@import url("../assets/css/confirmodal.css");
</style>