<template>
  <div class="history-cnt">
    <header class="inner-header">
      <div class="top-head">
        <span class="icon-btn back-icon" @click="$router.push('/')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
            />
          </svg>
        </span>
        <h1>Play History</h1>
      </div>
      <div class="win-blk">
        <div class="winning-status animated flipInX">
          <h2>Total Win</h2>
          <div class="award-status">
            <span class="coin-icon">
              <img
                src="../assets/images/m-coin.png"
                alt=""
                width="100"
                height="100"
              />
            </span>
            <span class="txt-value">{{ totalAmount }}</span>
          </div>
          <p>Your winning amount will be deposit in your account.</p>
        </div>
      </div>
    </header>
    <section class="mid-cnt">
      <div class="play-list">
        <ul>
          <li style="justify-content: center; color: #777" v-if="isLoading">
            Loading...
          </li>
          <li
            style="justify-content: center"
            v-else-if="
              userHistory && userHistory.filter((n) => n.award).length == 0
            "
          >
            No record found
          </li>
          <li
            v-else
            v-for="(user, i) in userHistory
              .filter((n) => n.award)
              .sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })"
            :key="i"
          >
            <div class="time-status">
              <h3>{{ user.createdAt.split("T")[0] }}</h3>
              <span class="txt-time">{{
                user.createdAt.split("T")[1].split(".")[0]
              }}</span>
            </div>

            <div class="award-status">
              <span class="coin-icon">
                <img
                  src="../assets/images/m-coin.png"
                  alt=""
                  width="100"
                  height="100"
                />
              </span>
              <span class="txt-value" v-if="user.award < 10">{{
                "0" + user.award
              }}</span>
              <span class="txt-value" v-else>{{
                user.award ? user.award : 0
              }}</span>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["userHistory"]),
  },
  data() {
    return {
      totalAmount: 0,
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("winHistory");
    this.isLoading = false;
    function totalSum(total, num) {
      return total + num;
    }
    if (
      this.userHistory &&
      this.userHistory.filter((n) => n.award).length > 0
    ) {
      this.totalAmount = this.userHistory
        .filter((n) => n.award)
        .map((m) => m.award)
        .reduce(totalSum);
    }
  },
};
</script>
<style scoped>
@import url("../assets/css/history.css");
</style>
