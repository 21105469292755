import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueConfetti from "vue-confetti";
import VueCookies from "vue-cookies";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import ads91 from "./ad-imp/index";

const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(VueConfetti)
  .use(VueCookies)
  .use(VueToast, {
    position: "top-right",
  })
  .use(ads91);
app.mount("#app");
