<template>
  <div class="all-banner" v-if="bannerAdList.length">
    <div class="text-banner">
      <div class="slide-bannerbx">
        <div
          class="ads-info"
          v-for="(item, index) in bannerAdList"
          :key="`swiperIndex${index}`"
        >
          <p
            class="title-head"
            :class="[
              { marquee: isNameView && !isScroll },
              { 'marquee-up': !isNameView && isScroll },
              { 'bottom-to-top': bottomToTop },
              { 'top-to-bottom': topToBottom },
            ]"
          >
            <!-- {
                'whatapp-openblk':
                  item.campaignAction.action == 'WHATS_APP_OPEN',
                'link-openblk': item.campaignAction.action == 'LINK_OPEN',
              }, -->
            <span id="name">{{
              isNameView || !isScroll ? item.name : item.description
            }}</span>
          </p>
        </div>
        <div class="shop-rgtbtn" v-if="bannerAdList && bannerAdList.length > 0">
          <span class="ad-btn">Ad</span>
          <span class="info-btn" @click="infoAdStatus = true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
              />
            </svg>
          </span>
          <span
            class="shop-btn"
            @click="followIntent(bannerAdList[0].campaignAction)"
            v-if="bannerAdList[0].campaignAction"
          >
            {{
              bannerAdList[0].campaignAction.action == "WHATS_APP_OPEN"
                ? "OPEN WHATSAPP"
                : "OPEN LINK"
            }}
          </span>
        </div>

        <div class="ad-info" v-if="infoAdStatus">
          <span class="back-btn" @click="infoAdStatus = !true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
              />
            </svg>
          </span>
          <div class="info-title">
            <h2>Ads by <span>M91</span></h2>
          </div>
          <p>
            Create your own ad, Download
            <a href="https://play.google.com/store/apps/details?id=com.money91"
              >Mall91 App</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from "swiper";
const { tellBackendViewed } = require("../utils/api");
import "./swiper.min.css";
export default {
  name: "Banner",
  data() {
    return {
      bottomToTop: false,
      topToBottom: false,
      swipCounter: -1,
      innerWidth: window.innerWidth,
      adName: null,
      adDesc: null,
      direction: "left",
      infoAdStatus: false,
      loop: 1,
      isMarqueeEnd: false,
      elemWidth: 0,
      isNameView: true,
      intervalId: null,
      isScroll: false,
    };
  },

  watch: {
    isNameView() {
      if (!this.isNameView) {
        clearInterval(this.intervalId);
      }
    },
    bannerAdList() {
      console.log(this.isNameView);
      this.elemWidth = 0;
      this.elemWidth = document.getElementById("name");
      this.isScroll = false;
      // this.isNameView = true;
      this.bannerAdList.map((n) => {
        if (n.name.length > 25) {
          this.isNameView = true;
          this.isScroll = false;
          this.topToBottom = true;
          setTimeout(() => {
            this.topToBottom = false;
          }, 3000);
          setTimeout(() => {
            this.intervalId = setInterval(() => {
              if (this.isNameView) {
                this.isNameView = this.elementInViewport2(this.elemWidth);
                if (!this.isNameView) {
                  this.isScroll = true;
                }
              }
            }, 200);
          }, 4000);
        } else {
          this.isNameView = false;
          this.isScroll = false;
          setTimeout(() => {
            this.isScroll = true;
          }, 4000);
        }
      });
      // if(this.elemWidth.offsetWidth > )
      // if (this.bottomToTop === true) {
      //   this.topToBottom = true;
      //   this.bottomToTop = false;
      //   console.log("top", this.bottomToTop);
      // } else if (this.topToBottom === true) {
      //   this.topToBottom = false;
      //   this.bottomToTop = true;
      //   console.log("bottom", this.topToBottom);
      // }
      // setTimeout(() => {
      //   console.log("======");
      //   if (this.bottomToTop || this.topToBottom) {
      //     this.bottomToTop = false;
      //     this.topToBottom = false;
      //   }
      // }, 2000);
    },
  },

  mounted() {
    this.adName = document.getElementById("title-anim");
    this.elemWidth = document.getElementById("name");

    // setInterval(() => {
    //   this.timer += 500;
    // }, 500);
    // console.log(this.elemWidth.offsetWidth);
    // this.adDesc = document.getElementById("ad-desc");
    // this.adDesc.style.display = "none";
    // console.log(this.adDesc);
    // this.adName.onfinish = function () {
    //   console.log("completed");
    // };

    this.bannerAdList.map((n) => {
      if (n.name.length > 25) {
        this.isNameView = true;
        this.isScroll = false;
        this.bottomToTop = true;
        setTimeout(() => {
          this.bottomToTop = false;
        }, 3000);
        setTimeout(() => {
          this.intervalId = setInterval(() => {
            if (this.isNameView) {
              this.isNameView = this.elementInViewport2(this.elemWidth);
              if (!this.isNameView) {
                this.isScroll = true;
              }
            }
          }, 200);
        }, 4000);
      } else {
        this.isNameView = false;
        this.isScroll = false;
        setTimeout(() => {
          this.isScroll = true;
        }, 4000);
      }
    });

    // if (this.bannerAdList.length - 1) {
    //   var mySwiper = new Swiper('.swiper-container', {
    //     loop: true,
    //     spaceBetween: 10,
    //     centeredSlides: true,
    //     autoplay: {
    //       delay: 4000,
    //       disableOnInteraction: false,
    //     },
    //     pagination: {
    //       el: '.swiper-pagination',
    //       clickable: true,
    //     },
    //     on: {
    //       slideChange: () => {
    //         this.swipCounter++;
    //         if (this.swipCounter > 0) {
    //           if (this.swipCounter <= [this.bannerAdList.length - 1])
    //             this.markViewed(this.bannerAdList[this.swipCounter]);
    //         }
    //       },
    //     },
    //   });
    // }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    bannerAdList: {
      type: Array,
      required: true,
    },
    userfingerPrint: {
      required: true,
    },
    unitIdentifier: {
      type: String,
      required: true,
    },
    timer: {
      type: Number,
      required: true,
    },
  },
  methods: {
    elementInViewport2(el) {
      var rect = el.getBoundingClientRect();
      let left = rect.left;
      while (el.offsetParent) {
        el = el.offsetParent;
        // rect.top += el.offsetTop;
        left += el.offsetLeft;
      }
      return (
        // rect.top >= 0 &&
        left < 0
        // rect.bottom <=
        //   (window.innerHeight ||
        //     document.documentElement
        //       .clientHeight) /* or $(window).height() */ &&
        // rect.right <=
        //   (window.innerWidth ||
        //     document.documentElement.clientWidth) /* or $(window).width() */
      );
    },
    followIntent(campaignAction) {
      this.$emit("followIntent", campaignAction);
    },
    markViewed(Ad) {
      tellBackendViewed(
        Ad.campaignIdentifier,
        this.userfingerPrint,
        this.unitIdentifier
      );
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700,900&display=swap");
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
::-webkit-scrollbar {
  display: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-size: 16px;
  background: #fff;
  font-family: "Roboto", sans-serif;
  transition: all ease 0.5s;
  color: #000;
}

.text-banner {
  width: 100%;
  height: auto;
  padding: 0px;
  display: block;
  background: #f4f6f8;
}

.slide-bannerbx {
  max-height: 60px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: 0px 42px 0px 0px;
  border-radius: 4px;
  box-shadow: 0 0 5px 0px #00000024;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  min-height: 60px;
}

.slide-bannerbx::before {
  width: 8px;
  height: 100%;
  position: absolute;
  left: -10px;
  top: 0px;
  content: "";
  z-index: 1;
  box-shadow: 0 0 10px 10px #fff;
  background: transparent;
}

.slide-bannerbx .ads-info {
  width: 100%;
  padding: 0;
  position: relative;
  display: flex;
  padding: 0 12px 0 0;
  align-items: center;
}

.slide-bannerbx .banner-rgt {
  width: 35%;
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
}

.slide-bannerbx .ads-info h2.title-head {
  font-size: 1em;
  color: #000;
  line-height: normal;
  margin: 0px;
  padding: 0px;
  font-weight: 600;
  width: 100%;
  transition: all ease 0.3s;
  position: relative;
  white-space: nowrap;
  text-transform: capitalize;
}

.slide-bannerbx .ads-info .title-head.bottom-to-top {
  margin: 36px 0 0 0;
}

.slide-bannerbx .ads-info .title-head.top-to-bottom {
  margin: -24px 0 0 0;
}

.slide-bannerbx .ads-info p {
  font-size: 1em;
  color: #000;
  line-height: normal;
  white-space: nowrap;
  transition: all ease 0.3s;
  display: block;
  font-weight: 600;
  margin: 0px;
  top: 0px;
  text-transform: capitalize;
  width: 100%;
}

.slide-bannerbx .ads-info p.marquee-up {
  width: calc(100% - 100px);
  padding: 0 0 0 12px;
}

.slide-bannerbx .ads-info p.marquee-up.whatapp-openblk {
}

.slide-bannerbx .ads-info p.marquee-up span {
  padding: 0px;
}

.slide-bannerbx .ad-btn {
  width: auto;
  height: auto;
  color: #fff;
  text-align: center;
  font-size: 10px;
  display: flex;
  line-height: normal;
  font-weight: 500;
  white-space: nowrap;
  margin: 0;
  background: #00000075;
  border-radius: 4px;
  padding: 2px 6px;
  margin: 0;
  position: absolute;
  left: auto;
  top: 4px;
  right: 4px;
}

.slide-bannerbx .info-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  top: auto;
  left: auto;
  right: 4px;
  bottom: 4px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 4px;
}

.slide-bannerbx .info-btn svg {
  width: 16px;
  height: 16px;
  fill: #727272;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slide-bannerbx .txt-logo {
  width: 42px;
  position: relative;
  line-height: 0;
  padding: 2px 0 2px 8px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 8px;
}

.slide-bannerbx .txt-logo::before {
  width: 1px;
  border-left: 2px dotted #ddd;
  position: absolute;
  left: 0px;
  top: 0px;
  content: "";
  height: 100%;
}

.slide-bannerbx .txt-logo img {
  max-width: 100%;
  height: auto;
  width: auto;
  position: relative;
  top: 3px;
}

.ad-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  background: #f4f6f8;
  left: 0px;
  top: 0px;
  padding: 8px 8px 8px 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.ad-info h2 {
  font-size: 1em;
  font-weight: 400;
  color: #505050;
}

.ad-info h2 span {
  font-weight: bold;
}

.ad-info p {
  font-size: 0.875em;
  color: #505050;
  line-height: normal;
  margin-bottom: 0px;
  font-weight: normal;
}

.ad-info .info-title {
  width: auto;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 0px;
}

.ad-info .back-btn {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
}
.ad-info .back-btn svg {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: #505050;
}

.shop-rgtbtn {
  width: auto;
  position: absolute;
  left: auto;
  right: 0px;
  background: #fff;
  height: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  padding: 0 42px 0 0px;
  z-index: 1;
}

.shop-rgtbtn::before {
  width: auto;
  height: 100%;
  position: absolute;
  left: -4px;
  top: 0px;
  content: "";
  z-index: 11;
  box-shadow: 0 0 10px 10px #fff;
  background: #fff;
  display: none;
}

.shop-rgtbtn::after {
  width: 1px;
  border-left: 1px dashed #ddd;
  position: absolute;
  left: auto;
  right: 34px;
  content: "";
  height: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.shop-rgtbtn .shop-btn {
  width: auto;
  height: auto;
  color: #fff;
  text-align: center;
  font-size: 11px;
  display: flex;
  line-height: normal;
  font-weight: 500;
  white-space: nowrap;
  margin: 0;
  background: #505050;
  border-radius: 6px;
  padding: 8px;
  margin: 0;
}

.ad-slide {
  display: flex;
  align-items: center;
}
.marquee {
  width: 450px;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee span {
  display: inline-block;
  width: max-content;

  padding-left: 100%;
  /* show the marquee just outside the paragraph */
  will-change: transform;
  animation: marquee 15s linear infinite;
}
.marquee-up span {
  display: inline-block;
  /* width: max-content; */
  /* height: auto; */
  /* max-height: 45px; */
  /* position: relative; */
  /* overflow: hidden; */
  white-space: pre-wrap;
  /* padding-top: 100%; */
  /* show the marquee just outside the paragraph */
  will-change: transform;
  animation: marquee-up 15s linear infinite;
  padding: 0 12px 0 0;
}

.marquee span:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes marquee-up {
  0% {
    transform: translate(0%, 60px);
  }
  100% {
    transform: translate(0%, -100px);
  }
}

/* Respect user preferences about animations */

@media (prefers-reduced-motion: reduce) {
  .marquee span {
    animation-iteration-count: 1;
    animation-duration: 0.01;
    /* instead of animation: none, so an animationend event is
     * still available, if previously attached.
     */
    width: auto;
    padding-left: 0;
  }
  .marquee-up span {
    animation-iteration-count: 1;
    animation-duration: 0.01;
    /* instead of animation: none, so an animationend event is
     * still available, if previously attached.
     */
    width: auto;
    padding-top: 0;
  }
}
</style>
