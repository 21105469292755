import { createRouter, createWebHashHistory } from 'vue-router';
// import Home from '../views/Home.vue'
import SlotGame from '../components/SlotGame.vue';
import PlayHistory from '../views/PlayHistory.vue';
import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

const routes = [
  {
    path: '/',
    name: 'SlotGame',
    component: SlotGame,
  },

  {
    path: '/play-history',
    name: 'PlayHistory',
    component: PlayHistory,
  },

  {
    path: '/user/:xauthId',
    redirect: (to) => {
      cookies.set('X-Auth-Id', to.params.xauthId);
      return {
        name: 'SlotGame',
      };
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
