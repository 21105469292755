import { createStore } from "vuex";
import CONSTANTS from "../utils/constant";
import axios from "axios";
export default createStore({
  state: {
    userInfo: false,
    displayResult: false,
    xauth: {
      headers: {
        "X-Auth-Id": null,
      },
    },
    userAward: "",
    userHistory: [],
    apiIssue: "",
    lowBalance: "",
    errorMessage: "",
  },
  mutations: {
    SET_USER(state, payload) {
      state.userInfo = payload;
    },
    SET_DISPLAYRESULT(state, payload) {
      state.displayResult = payload;
    },
    SET_USERWIN(state, payload) {
      state.userAward = payload;
    },
    SET_USERHISTORY(state, payload) {
      state.userHistory = payload;
    },
    SET_API_ISSUE(state, payload) {
      state.apiIssue += payload;
    },
    SET_LOWBALANCE(state, payload) {
      state.lowBalance += payload;
    },
    SET_XAUTH(state, payload) {
      state.xauth.headers["X-Auth-Id"] = payload;
    },
    SET_ERR_MSG(state, payload) {
      state.errorMessage = payload;
    },
  },
  actions: {
    async user({ state, commit }) {
      try {
        let { data } = await axios.get(
          `${CONSTANTS.BASE_URL}/user`,
          state.xauth
        );
        commit("SET_USER", data);
      } catch (error) {
        commit("SET_API_ISSUE", 1);
      }
    },

    async displayResult({ state, commit }) {
      commit("SET_ERR_MSG", "");
      try {
	let user_xauth = state.xauth.headers['X-Auth-Id'];
        let { data } = await axios.get(
          `${CONSTANTS.BASE_URL}/slot-result?q=${user_xauth}`,
          state.xauth
        );
        if (data.status) {
          commit("SET_DISPLAYRESULT", data.data);
        }
        if (!data.status) {
          commit("SET_API_ISSUE", 1);
          commit("SET_ERR_MSG", data.data.message);
        }
      } catch (error) {
        commit("SET_API_ISSUE", 1);
      }
    },

    async actualResult({ state, commit }, payload) {
      try {
        let { data } = await axios.post(
          `${CONSTANTS.BASE_URL}/declare-result`,
          {
            id: payload,
          },
          state.xauth
        );
        commit("SET_USERWIN", data);
      } catch (error) {
        commit("SET_API_ISSUE", 1);
      }
    },
    async winHistory({ state, commit }) {
      commit("SET_ERR_MSG", "");
      try {
        let { data } = await axios.get(
          `${CONSTANTS.BASE_URL}/users-history`,
          state.xauth
        );
        if (data.status) {
          commit("SET_USERHISTORY", data.data);
        }
        if (!data.status) {
          commit("SET_API_ISSUE", 1);
          commit("SET_ERR_MSG", data.data.message);
        }
      } catch (error) {
        commit("SET_API_ISSUE", 1);
      }
    },
  },
  modules: {},
});
