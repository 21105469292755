<template>
  <div
    class="ads-product"
    v-if="Object.keys(this.data).length"
    @click="!data.productMedia.isButtonRequired ? followIntent(data.campaignAction) : ''"
  >
    <span class="label">
      <img src="../images/logo.svg" alt />
    </span>
    <div class="title-blk">
      <span class="img-box">
        <img :src="data.productMedia.mediaURL" alt />
      </span>
      <h2>{{ data.productMedia.productName }}</h2>
      <div class="price-detail">
        <div class="item">
          <span class="selling-price"
            >₹{{ data.productMedia.sellingPrice }}</span
          >
          <span class="selling-cut" v-if="data.productMedia.mrp"
            >₹{{ data.productMedia.mrp }}
          </span>
        </div>
        <span class="valid-date">{{
          "Valid Till: " + extractDate(data.endDate)
        }}</span>
        <div
          class="button-item"
          v-if="data.productMedia.isButtonRequired"
          @click="followIntent(data.campaignAction)"
        >
          <button class="button">Buy Now</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductCard",
  data: () => ({}),
  props: { data: { type: Object, required: !0 } },
  methods: {
    followIntent(campaignAction) {
      this.$emit("followIntent", campaignAction);
    },
    extractDate: t =>
      new Date(t).getDate() +
      " " +
      [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ][new Date(t).getMonth()]
  }
};
</script>
<style scoped>
.ads-product {
  width: 100%;
  background: #f4f6f8;
  position: relative;
  padding: 8px;
  border-radius: 8px;
}
.ads-product .img-box {
  width: 45px;
  height: 45px;
  background: #fff;
  position: relative;
  overflow: hidden;
  float: left;
  border-radius: 100%;
  margin: 0 12px 0 0;
  position: absolute;
  left: 0;
  top: 0;
}
.ads-product .img-box img {
  width: 45px;
  height: 45px;
  border-radius: 100px;
  object-fit: cover;
  object-position: center;
}
.price-detail {
  display: flex;
  align-items: center;
  line-height: normal;
}

.price-detail .selling-price {
  font-size: 0.875em;
  font-weight: bold;
}

.price-detail .selling-cut {
  font-size: 0.875em;
  font-weight: bold;
  text-decoration: line-through;
  margin-left: 4px;
  color: #727272;
}

.title-blk {
  position: relative;
  padding: 0 45px 0 55px;
}
.title-blk h2 {
  font-size: 0.875em;
  font-weight: 600;
  color: #262626;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ads-product .mrp {
  font-size: 0.75em;
  line-height: 25px;
  color: #7d7d7d;
  margin: 4px 2px;
}
.ads-product .valid-date {
  color: #ff0000;
  font-size: 0.7em;
  margin: 0 4px;
  font-weight: 500;
  position: relative;
  top: 1px;
}
.ads-product p {
  font-size: 0.8em;
  line-height: 18px;
  color: #7d7d7d;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 3;
  max-height: 100px;
  -webkit-box-orient: vertical;
}
.button-box {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.button-box .item {
  font-size: 0.875em;
  color: #4a4747;
  font-weight: 500;
}
.button-box .item .mrp {
  text-decoration-line: line-through;
  color: red;
}
.button-box .item strong {
  font-weight: 700;
  color: green;
  padding-left: 6px;
}
.price-detail .button-item {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.price-detail .button {
  width: auto;
  height: auto;
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
  background: #138708;
  border: none;
  transition: all ease 0.3s;
  padding: 8px;
  font-size: 0.7em;
}
.ads-product .label {
  width: auto;
  position: absolute;
  right: 8px;
  top: 8px;
}
.ads-product .label img {
  height: 18px;
}
.close {
  width: 44px;
  height: 15px;
  background: #eceef0;
  font-size: 0.576em;
  color: #147bce;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 2px;
  font-weight: 700;
}
</style>
