<template ref="slotgame">
  <div id="app">
    <ads-91 :unitIdentifier="identifier" adENUM="1" />
    <div class="slot-gamecnt">
      <div class="slot-titlblk">
        <div class="user-stateblk">
          <div class="amt-status">
            <span class="coin-icon">
              <img src="../assets/images/m-coin.png" alt="" width="100" height="100" />
            </span>
            <span class="txt-val" id="tokenBalance">0</span>
          </div>

          <div class="history-status" @click="$router.push('/play-history')">
            <span class="history-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
              </svg>
            </span>
            <span class="txt-title">Play History</span>
          </div>
        </div>
        <h1>Coin<span>Machine</span></h1>
      </div>

      <div v-if="showWin" class="slot-winner-msg">
        <div class="win-txtblk">
          <h2>You Won</h2>
          <div class="won-amt">
            <span class="coin-icon">
              <img src="../assets/images/m-coin.png" alt="" width="100" height="100" />
            </span>
            <span class="txt-val">{{ award }}</span>
          </div>
        </div>
      </div>

      <div class="slot-doors">
        <div class="doors">
          <span class="corn-tick-lft"></span>
          <span class="corn-tick-rgt"></span>
          <div class="door">
            <div class="boxes"></div>
          </div>

          <div class="door">
            <div class="boxes"></div>
          </div>

          <div class="door">
            <div class="boxes"></div>
          </div>
        </div>
      </div>

      <div style="color: white">
        <!-- counts -->
      </div>

      <div class="slot-btn-blk">
        <div v-if="spinStatus" id="spinner" class="slot-btn spin-btn" @click="spin">
          {{ buttonText }}
          <span class="shine-bar"></span>
        </div>

        <div v-else class="slot-btn spin-disable">{{ buttonText }}</div>
      </div>
      <!-- <button id="reseter" @click="init">Reset</button> -->

      <div class="slot-icon-blk">
        <span class="img-icon" v-for="(item, index) in items" :key="index">
          <img :src="item.keyIcon" alt="icon" />
        </span>

        <confirm-modal :isModalOpen="isModalOpen" @handleCloseModal="handleClose" />
      </div>
    </div>
    <loader :isLoading="isLoading" />
    <!-- <ads-91 unitIdentifier="9e7f3187-1e24-42bc-ac9e-f8164c1a7419" adENUM="1" /> -->
  </div>
</template>
<script>
import startSound from "../assets/sounds/reelsBegin.mp3";
import win1 from "../assets/sounds/win1.mp3";
import win2 from "../assets/sounds/win2.mp3";
import win3 from "../assets/sounds/win3.mp3";
import ConfirmModal from "./ConfirmModal.vue";
import { mapState } from "vuex";
import Loader from "./Loader.vue";

export default {
  name: "SlotGame",
  components: { ConfirmModal, Loader },
  watch: {
    spinTime() {
      if (this.spinTime == 2700) this.spinStatus = false;
      else this.spinStatus = true;
    },
  },
  computed: {
    ...mapState([
      "xauth",
      "userInfo",
      "displayResult",
      "userAward",
      "userHistory",
      "errorMessage",
      "userHistory",
    ]),
  },
  data() {
    return {
      items: [
        {
          id: 1,
          keyIcon: require("@/assets/images/apple.svg"),
        },
        {
          id: 2,
          keyIcon: require("@/assets/images/lemon.svg"),
        },
        {
          id: 3,
          keyIcon: require("@/assets/images/7.svg"),
        },
        {
          id: 4,
          keyIcon: require("@/assets/images/watermelon.svg"),
        },
        {
          id: 5,
          keyIcon: require("@/assets/images/crypto.svg"),
        },
        {
          id: 6,
          keyIcon: require("@/assets/images/cherry.svg"),
        },
      ],
      result: [],
      firstTime: true,
      pool: [],
      isLoading: false,
      winResult: false,
      isModalOpen: false,
      spinStatus: true,
      showWin: false,
      award: "",
      c91Balance: 0,
      buttonText: "Spin",
      audio: "",
      upperBalance: "",
      lowerBalance: "",
      counts: "",
      identifier: "9e7f3187-1e24-42bc-ac9e-f8164c1a7419",
      spinCount: 0,
      spinLimit: 10,
      spinRateLock: false,
    };
  },
  async mounted() {
    this.init();
    this.spinStatus = false;
    this.buttonText = "Loading..";
    await this.$store.dispatch("user");
    // await this.$store.dispatch("winHistory");
    this.buttonText = "Spin";
    this.spinStatus = true;
    // this.spinCount =
    //   this.userHistory.length > 0
    //     ? this.userHistory.filter((n) => {
    //         // console.log(new Date(n.createdAt).toLocaleString().split(",")[0]);
    //         // console.log(new Date().toLocaleString().split(",")[0]);
    //         return (
    //           n.award &&
    //           new Date(n.createdAt).toLocaleString().split(",")[0] ==
    //             new Date().toLocaleString().split(",")[0]
    //         );
    //       }).length
    //     : 0;
    // this.$cookies.set("spinCount", this.spinCount);
    var count = document.getElementById("tokenBalance");
    count.innerHTML = this.userInfo.data.c91WalletBalance;
    this.lowerBalance = this.userInfo.data.c91WalletBalance;
    this.upperBalance = this.userInfo.data.c91WalletBalance;
    if (!this.userInfo) {
      this.spinStatus = false;
    }
  },
  methods: {
    handleClose(payload) {
      this.isModalOpen = payload;
    },

    getStartSound() {
      this.audio = new Audio(startSound);
      this.audio.play();
      // console.log("sound start");
    },

    getWin1Sound() {
      this.audio = new Audio(win1);
      this.audio.play();
      // console.log("sound winner");
    },
    getWin2Sound() {
      this.audio = new Audio(win2);
      this.audio.play();
      // console.log("sound end");
    },
    getWin3Sound() {
      this.audio = new Audio(win3);
      this.audio.play();
      // console.log("sound end");
    },
    audioStop() {
      if (this.audio != "") {
        // console.log(this.audio);
        this.audio.pause();
      }
    },
    async counterUpdate() {
      if (this.lowerBalance < this.upperBalance) this.c91Balance = ++this.lowerBalance;
      else if (this.lowerBalance > this.upperBalance) this.c91Balance = --this.lowerBalance;
      var count = document.getElementById("tokenBalance");
      count.innerHTML = this.lowerBalance;
      if (this.lowerBalance == this.upperBalance) {
        clearInterval(this.counts);
      }
    },
    async sleep(time) {
      return new Promise((res) => {
        setTimeout(() => {
          res();
        }, time);
      });
    },
    async spin() {
      // if (this.lowerBalance < 10) await this.$store.commit("SET_LOWBALANCE", 1);
      // if (this.spinCount < this.spinLimit) {
      if (this.buttonText == "Spin") {
        if (this.spinRateLock) {
          this.spinStatus = false;
          return;
        }
        this.spinRateLock = true; // enter lock

        // this.spinCount++;
        // this.$cookies.set("spinCount", this.spinCount);
        // console.log(this.lowerBalance,this.upperBalance);
        this.isLoading = true;
        await this.$store.dispatch("displayResult");
        this.isLoading = false;
        if (this.errorMessage == "") {
          this.upperBalance = this.lowerBalance - 10;
          this.counts = setInterval(async () => {
            await this.counterUpdate();
          }, 100);
          this.$confetti.stop();
          this.audioStop();
          this.buttonText = "Loading..";
          this.showWin = false;
          this.spinStatus = false;
          this.buttonText = "Spin";
          this.init(true, 1, 1);
          this.init(false, 1, 2);
          const doors = document.querySelectorAll(".door");

          setTimeout(async () => {
            if (!this.winResult) {
              if (this.displayResult) {
                this.isLoading = true;
                await this.$store.dispatch("actualResult", this.displayResult.result.id);
                this.isLoading = false;
                this.award = this.userAward.data.award;
                this.lowerBalance = this.upperBalance;
                this.upperBalance = this.upperBalance + this.award;
              }
              let result = this.displayResult.result.finalItems;
              if (result[0].id == result[1].id && result[0].id == result[2].id) {
                this.getWin1Sound();
                this.$confetti.start({
                  particlesPerFrame: 1.5,
                  particles: [
                    {
                      type: "rect",
                    },
                  ],
                });
                setTimeout(() => {
                  this.$confetti.stop();
                }, 5000);
              } else if (
                result[0].id == result[1].id ||
                result[1].id == result[2].id ||
                result[2].id == result[0].id
              ) {
                this.getWin2Sound();
                this.$confetti.start({
                  particlesPerFrame: 1.5,
                  particles: [
                    {
                      type: "rect",
                    },
                  ],
                });
                setTimeout(() => {
                  this.$confetti.stop();
                }, 2000);
              } else {
                this.getWin3Sound();
                this.$confetti.start({
                  particlesPerFrame: 1.5,
                  particles: [
                    {
                      type: "rect",
                    },
                  ],
                });
                setTimeout(() => {
                  this.$confetti.stop();
                }, 2000);
              }
              // this.spinStatus = true; // will be enabled by lock
              this.sleep(10000).then(() => {
                console.log("reseting time lock");
                this.spinRateLock = false;
                this.spinStatus = true;
              });
              this.showWin = true;
              // console.log(this.lowerBalance,this.upperBalance);
              // this.counts=setInterval(this.updated);
              this.counts = setInterval(async () => {
                await this.counterUpdate();
              }, 100);
              setTimeout(() => {
                if (this.showWin) document.querySelector(".win-txtblk").classList.add("winFlipOut");
              }, 3500);

              setTimeout(() => {
                if (this.showWin) {
                  document.querySelector(".win-txtblk").classList.remove("winFlipOut");
                }
              }, 1000);
            } else {
              // this.spinStatus = true;  will be enabled by lock
              this.sleep(10000).then(() => {
                console.log("reseting time lock else");
                this.spinRateLock = false;
                this.spinStatus = true;
              });
              this.showWin = true;
            }
          }, 2700);

          for (const door of doors) {
            const boxes = door.querySelector(".boxes");
            const duration = parseInt(boxes.style.transitionDuration);
            boxes.style.transform = "translateY(0)";
            await new Promise((resolve) => setTimeout(resolve, duration * 100));
            // console.log("2 duration",duration);
          }
          // await this.$store.dispatch('winHistory');
          //   new Audio('../sounds/reelsEnd.mp3').play();
          // console.log(this.result);
        }
      }
      // } else {
      //   this.$toast.info(
      //     `You have reached your daily limit of ${this.spinLimit}.`
      //   );
      // }
    },

    init(firstInit = true, groups = 1, duration = 1) {
      const doors = document.querySelectorAll(".door");
      if (!firstInit) {
        this.getStartSound();
      }

      let i = 0;
      for (const door of doors) {
        if (firstInit) {
          door.dataset.spinned = "0";
        } else if (door.dataset.spinned === "1") {
          return;
        }

        const boxes = door.querySelector(".boxes");
        const boxesClone = boxes.cloneNode(false);

        // this.pool = [this.result[0]];
        if (this.firstTime) this.pool = [require("@/assets/images/query.svg")];
        if (!this.firstTime) this.pool = [];
        // this.result.map(n=>{
        //   console.log(n);
        // })
        if (!firstInit) {
          const arr = [];
          for (let n = 0; n < (groups > 0 ? 4 : 1); n++) {
            arr.push(...this.items);
          }
          this.pool.push(...this.shuffle(arr));
          if (this.displayResult.result.finalItems) {
            this.items.forEach((element) => {
              if (element.id == this.displayResult.result.finalItems[i].id) {
                this.pool[this.pool.length - 1] = element;
              }
            });
          }
          //  console.log(this.pool[this.pool.length - 1].id);
          this.result[i++] = this.pool[this.pool.length - 1].id;
          //  console.log("result",this.result);

          boxesClone.addEventListener(
            "transitionstart",
            function () {
              door.dataset.spinned = "1";
              this.querySelectorAll(".box").forEach((box) => {
                box.style.filter = "blur(5px)";
              });
            },
            { once: false }
          );

          boxesClone.addEventListener(
            "transitionend",
            function () {
              this.querySelectorAll(".box").forEach((box, index) => {
                box.style.filter = "blur(0)";
                if (index > 0) this.removeChild(box);
              });
            },

            { once: false }
          );
        }
        // console.log(this.pool);
        for (let i = this.pool.length - 1; i >= 0; i--) {
          const box = document.createElement("div");
          box.classList.add("box");
          box.style.width = door.clientWidth + "px";
          box.style.height = door.clientHeight + "px";
          //  if(i>this.pool.length)
          //     i=i-this.pool.length

          if (this.pool[i] == "❓") {
            box.innerText = this.pool[i];
          } else if (this.pool[i] == require("@/assets/images/query.svg")) {
            box.innerHTML = `<img src="${this.pool[i]}" alt='icon'>`;
          } else {
            box.innerHTML = `<img src="${this.pool[i].keyIcon}" alt='icon'>`;
          }
          boxesClone.appendChild(box);
        }
        boxesClone.style.transitionDuration = `${duration > 0 ? duration : 1}s`;
        boxesClone.style.transform = `translateY(-${door.clientHeight * (this.pool.length - 1)}px)`;
        door.replaceChild(boxesClone, boxes);
        //  console.log(door);
      }
      this.firstTime = false;
    },

    shuffle([...arr]) {
      let m = arr.length;
      while (m) {
        const i = Math.floor(Math.random() * m--);
        [arr[m], arr[i]] = [arr[i], arr[m]];
      }
      return arr;
    },

    // love() {
    //   this.$confetti.update({
    //     particles: [
    //       {
    //         type: 'heart',
    //       },
    //       {
    //         type: 'circle',
    //       },
    //     ],
    //     defaultColors: [
    //       'red',
    //       'pink',
    //       '#ba0000'
    //     ],
    //   });
    // }
  },
};
</script>

<style scoped>
@import url("../assets/css/slotgame.css");
</style>
