<template>
  <div v-if="Ad && Object.keys(Ad).length" :id="Ad.campaignIdentifier">
    <Banner
      v-if="Ad.mediaType == 1 || Ad.mediaType == 4"
      :data="Ad"
      :bannerAdList="bannerAdList"
      @followIntent="followIntent"
      :userfingerPrint="userfingerPrint"
      :unitIdentifier="unitIdentifier"
      :timer="timer"
    />
    <Video
      v-else-if="Ad.mediaType == 2"
      :data="Ad"
      @followIntent="followIntent"
      @markClicked="markClicked"
    />
    <ProductCard
      v-else-if="Ad.mediaType == 3"
      :data="Ad"
      @followIntent="followIntent"
    />
  </div>
</template>
<script>
import Video from "./Video";
import Banner from "./Banner";
import ProductCard from "./ProductCard";
import { followRespectiveIntent } from "../utils/helpers";
const {
  init,
  tellBackendViewed,
  tellBackendClicked,
  getUserId,
} = require("../utils/api");
const Fingerprint2 = require("fingerprintjs2");

export default {
  name: "Ads",
  components: {
    Video,
    Banner,
    ProductCard,
  },
  data() {
    return {
      userfingerPrint: false,
      Ad: false,
      viewed: false,
      clicked: false,
      index: 0,
      bannerAdList: [],
      pincode: null,
      xauthid: null,
      user: null,
      timer: 0,
    };
  },
  props: {
    unitIdentifier: {
      type: String,
      required: true,
    },
    adENUM: {
      type: String,
      validator: (val) => ["1", "2", "3", "4"].includes(val),
    },
  },
  async mounted() {
    // setInterval(() => {
    //   this.timer += 10;
    // }, 500);
    this.xauthid = this.getCookie("X-Auth-Id");
    this.createFingerPrint();
    setInterval(() => {
      this.createFingerPrint();
    }, 30000);
    this.user = await getUserId(this.xauthid);
    console.log(this.user.id);
  },
  methods: {
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
    createFingerPrint() {
      var this_ = this;
      setTimeout(() => {
        Fingerprint2.get((components) => {
          this_.userfingerPrint = Fingerprint2.x64hash128(
            components
              .map((pair) => {
                return pair.value;
              })
              .join(),
            31
          );
          this_.intialiseAds();
        });
      }, 500);
    },
    async intialiseAds() {
      const Ads = await init(
        this.adENUM,
        this.unitIdentifier,
        this.userfingerPrint,
        this.user
      );
      this.Ad =
        Ads[
          this.index
        ]; /*we can change index here through setInterval to change ads*/
      this.bannerAdList = Ads;
      setTimeout(() => {
        if (this.Ad) this.checkViewed();
      }, 500);
    },
    checkViewed() {
      console.log("enter");
      var this_ = this;
      var observer = new IntersectionObserver(
        function (entries) {
          console.log("entries---", entries);
          if (entries[0].isIntersecting === true) {
            console.log("entries---call");
            this_.markViewed();
          }
        },
        { threshold: [1] }
      );
      observer.observe(document.getElementById(this.Ad.campaignIdentifier));
    },
    async markViewed() {
      if (!this.viewed) {
        this.viewed = true;
        this.viewed = await tellBackendViewed(
          this.Ad.campaignIdentifier,
          this.userfingerPrint,
          this.unitIdentifier,
          this.user
        );
      }
    },
    followIntent(data) {
      this.markClicked();
      followRespectiveIntent(data);
    },
    async markClicked() {
      if (!this.clicked) {
        this.clicked = true;
        this.clicked = await tellBackendClicked(
          this.Ad.campaignIdentifier,
          this.userfingerPrint,
          this.unitIdentifier,
          this.user
        );
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700,900&display=swap");
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
::-webkit-scrollbar {
  display: none;
  -webkit-tap-highlight-color: transparent;
}
</style>
