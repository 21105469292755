import apicaller from "./apicaller";
import axios from "axios"

export const getUserId = async (xauthid) => {
  const { data } = await axios.get(`https://dev1.money91.com/redis/userinfo/${xauthid}`);
  if (data) return data.data
  else return null;
};

export const init = async (adENUM, unitIdentifier, userfingerPrint, user) => {
  if (unitIdentifier && userfingerPrint) {
    const data = await apicaller(
      `/process-ads/${unitIdentifier}?userfingerPrint=${userfingerPrint}&pincode=${user.pincode}&pbUserId=${user.id}`,
      "get",
      { "Content-Type": "application/json", "appName": "miniapp" }
    );
    if (
      data &&
      data.data &&
      data.data.message == "Data retrieved successfully" &&
      data.data.data.length
    ) {
      return adENUM
        ? data.data.data.filter((e) => e.mediaType == adENUM)
        : data.data.data;
    } else if (data.data.message == "Data retrieved successfully") {
      // console.log("No ads found");
    } else {
      console.log("error in fetching ads");
    }
    return [];
  }
};

export const tellBackendViewed = async (
  campaignIdentifier,
  userfingerPrint,
  unitIdentifier,
  user
) => {
  const response = await apicaller(
    `/impression-viewed/${campaignIdentifier}/${userfingerPrint}?unitIdentifier=${unitIdentifier}&pincode=${user.pincode}&name=${user.nickName + user.lastName}&phoneNumber=${user.phone}&pbUserId=${user.id}`,
    "get",
    { "appName": "miniapp" }
  );
  return response.data == "success" ? true : false;
};

export const tellBackendClicked = async (
  campaignIdentifier,
  userfingerPrint,
  unitIdentifier,
  user
) => {
  const response = await apicaller(
    `/click-tracking?campaignIdentifier=${campaignIdentifier}&userfingerPrint=${userfingerPrint}&unitIdentifier=${unitIdentifier}&pincode=${user.pincode}&name=${user.nickName + user.lastName}&phoneNumber=${user.phone}&pbUserId=${user.id}`,
    "get",
    { "appName": "miniapp" }
  );
  return response.data == "success" ? true : false;
};
