<template>
  <router-view />
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    this.$store.commit("SET_XAUTH", this.$cookies.get("X-Auth-Id"));
  },
  computed: {
    ...mapState(["apiIssue", "lowBalance", "errorMessage"]),
  },
  watch: {
    apiIssue() {
      this.$toast.open({
        message: this.errorMessage || "Something went wrong!",
        type: "error",
        duration: 5000,
        dismissible: true,
      });
    },
    lowBalance() {
      this.$toast.open({
        message: "Low Balance!",
        type: "error",
        duration: 3000,
        dismissible: true,
      });
    },
  },
};
</script>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
// }
</style>
